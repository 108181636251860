import { toast } from 'react-toastify';

export const loadingMsg = () => {
  toast.dismiss();
  toast.loading('Please wait...');
};

export const successMsg = (msg) => {
  toast.dismiss();
  toast.success(msg, {
    autoClose: 1000,
  });
};

export const errorMsg = (msg) => {
  toast.dismiss();
  toast.error(msg);
};
