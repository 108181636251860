// reducers/authReducer.js
import * as types from '../types/authTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGNOUT_REQUEST:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
