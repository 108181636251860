import React, { useState, useEffect, lazy } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { JWT_ACCESS_TOKEN_KEY } from 'lib/network/jwt-token-access';
import Loadable from 'layouts/full/shared/loadable/Loadable';

const FullLayout = Loadable(lazy(() => import('layouts/full/FullLayout')));

const AppRoute = () => {
  const token = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
  const location = useLocation();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const simulateLoading = () => {
      setIsLoading(true);

      const interval = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setIsLoading(false);
            return 0;
          }
          return prevProgress + 1;
        });
      }, 30);

      setTimeout(() => {
        clearInterval(interval);
        setIsLoading(false);
      }, 500);
    };

    simulateLoading();
  }, [location]);

  return (
    <>
      {' '}
      {isLoading && (
        <LinearProgress
          variant="determinate"
          value={loadingProgress}
          className="top-0 left-0 w-full"
          style={{ zIndex: 5, position: 'absolute' }}
        />
      )}
      {token ? (
        <FullLayout>
          <Outlet />
        </FullLayout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default AppRoute;

// import { Outlet, Navigate } from 'react-router-dom'

// const PrivateRoutes = () => {
//     let auth = {'token':false}
//     return(
//         auth.token ? <Outlet/> : <Navigate to="/login"/>
//     )
// }

// export default PrivateRoutes
