import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import merchantRoleAccessReducer from './merchantRoleAccessReducer';

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  merchantRoleAccess: merchantRoleAccessReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
